import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert } from "antd";
import { Part } from "app/models/Part";
import { UserRole } from "app/models/UserRole";
import { authSelector } from "app/redux/slides/auth.slide";
import { configSelector } from "app/redux/slides/config.slide";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface DefaultProps {
  part: Part;
}

function AlertManualReason(props: DefaultProps) {
  const { part } = props;
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const config = useSelector(configSelector);
  const [manualReason, setManualReason] = useState<any>("");
  useEffect(() => {
    if (part) {
      switch (part.manualReason) {
        case "CALC_ERR_UNIT_PRICE_NULL":
        case "CALC_ERR_PARSE_TECH_DRAWING_DATA":
        case "CALC_ERR_GET_3D_MODEL":
        case "CALC_ERR_SHAPE_ANALYSIS_NOT_FOUND":
        case "CALC_ERR_GET_DXF_CONTENT":
        case "CALC_ERR_INVALID_LASER_GAS":
        case "CALC_ERR_OPERATION_CUTTING_NOT_FOUND":
        case "CALC_ERR_LASER_TIME_SERVICE":
          setManualReason("CALC_ERR");
          break;
        default:
          setManualReason(part.manualReason);
          break;
      }
    }
  }, [part]);
  if (!part || part?.auto) return <></>;

  const keyMessage = auth.user?.role == UserRole.BUYER ? "buyer" : "seller";
  return (
    <Alert
      className="mb-3"
      message={
        <>
          {auth.user?.role === UserRole.BUYER
            ? t("project.manualNote")
            : t("project.manual.seller.part.note")}
          {t(`part.manual.${keyMessage}.${manualReason}`) &&
            t(`part.manual.${keyMessage}.${manualReason}`, {
              defaultValue: "",
            }) && (
              <>
                <br />
                {t("reason")}:{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      `part.manual.${keyMessage}.${manualReason}`,
                      {
                        email: config.supportMail,
                      }
                    ),
                  }}
                ></span>
              </>
            )}
        </>
      }
      type="info"
      icon={<InfoCircleOutlined />}
      showIcon
    />
  );
}

export default AlertManualReason;
