import { useEffect, useState } from "react";
import { Project } from "../../models/Project";
import CurrencyFormat from "../Format/CurrencyFormat";
import { useTranslation } from "react-i18next";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import projectServices from "../../services/project.service";
import TotalPartPrice from "./TotalPartPrice";
import SurfaceTreatmentSurcharge from "./SurfaceTreatmentSurcharge";
import PaymentFormModal from "../Payment/PaymentFormModal";
import PaymentForm from "../Payment/PaymentForm";
import "./projectPrice.module.scss";
import { config } from "process";
import { useSelector } from "react-redux";
import { configSelector } from "app/redux/slides/config.slide";
import { authSelector } from "app/redux/slides/auth.slide";
import { UserRole } from "app/models/UserRole";
import ProjectListManualPaymentFormModal from "../Payment/ProjectListManualPaymentFormModal";
import Utils from "app/utils";

interface DefaultProps {
  project: Project;
  onCompletePayment?: any;
  accessCode?: string;
}
function ProjectPrices(props: DefaultProps) {
  const { project, onCompletePayment, accessCode } = props;
  const { t } = useTranslation();
  const [deliveryCost, setDeliveryCost] = useState(project.order?.price);
  const [downloadingPreviewOrder, setDownloadingPreviewOrder] = useState(false);
  const [triggerShowModal, setTriggerShowModal] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showPaid, setShowPaid] = useState(false);
  const [showVat, setShowVat] = useState(false);
  const [showLoadingPaid, setLoadingPaid] = useState(false);
  const config = useSelector(configSelector);
  const auth = useSelector(authSelector);

  useEffect(() => {
    setDeliveryCost(project.order?.price);
    checkPaymentStatus();
    if (project?.order?.shippingAddress?.country == config.shopCountry) {
      setShowVat(true);
    } else {
      setShowVat(false);
    }
  }, [project]);

  const onDownloadPreviewOffer = async () => {
    try {
      setDownloadingPreviewOrder(true);
      await projectServices.downloadPreviewOrder({
        id: project.id,
        name: project.name || "GOCAD",
      });
    } catch (error) {}
    setDownloadingPreviewOrder(false);
  };

  const handleOnCompletePayment = () => {
    setTriggerShowModal(false);
    setShowPayment(false);
  };

  const checkPaymentStatus = async () => {
    if (!config?.paymentEnabled || !project?.id) return;

    try {
      setLoadingPaid(true);
      await Utils.waitForSeconds(3);
      let rs: any;

      if (accessCode) {
        rs = await projectServices.viewByCode(accessCode);
      } else if (accessCode) {
        rs = await projectServices.detail(project?.id);
      }

      setLoadingPaid(false);
      if (!rs?.order?.paid) {
        setShowPayment(true);
        setShowPaid(false);
      } else {
        setShowPaid(true);
        setShowPayment(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (!deliveryCost) return <></>;
  return (
    <div className="w-100 summary-price">
      <TotalPartPrice price={deliveryCost} />
      <SurfaceTreatmentSurcharge price={deliveryCost} />
      <div className="d-flex">
        <label>
          {t("price.deliveryOptionPrice")} (
          {t(`deliveryOption.${project.deliveryOption}`)})
        </label>
        <label className="ms-auto">
          <CurrencyFormat value={deliveryCost.deliveryOptionPrice} />
        </label>
      </div>
      <div className="d-flex">
        <label>{t("price.packagingPrice")}</label>
        <label className="ms-auto">
          <CurrencyFormat value={deliveryCost.packagingPrice} />
        </label>
      </div>
      <div className="d-flex shipping-cost">
        <label>{t("price.shipping")}</label>
        <label className="ms-auto">
          <CurrencyFormat value={deliveryCost.shipping} />
        </label>
      </div>
      <div className="text-muted">
        <i>{t(`shippingOption.${project?.order?.shippingOption}`)}</i>
      </div>
      <hr />
      <div className="d-flex fw-bold">
        <label>{t("price.netTotal")}</label>
        <label className="ms-auto">
          <CurrencyFormat value={deliveryCost.netTotal} />
        </label>
      </div>
      {showVat && (
        <div className="d-flex">
          <label>{t("price.vat", { vat: config.shopCountryVatRate })}</label>
          <label className="ms-auto">
            <CurrencyFormat value={deliveryCost.vat} />
          </label>
        </div>
      )}
      <hr />
      <div className="d-flex fw-bold">
        <label>{t("price.total")}</label>
        <label className="ms-auto total-price">
          <CurrencyFormat value={deliveryCost.total} />
        </label>
      </div>
      <hr />
      <div className="row">
        <div className="col col-md-7 col-xs-12">
          <a onClick={onDownloadPreviewOffer}>
            <PdfFileIcon /> {t("project.checkout.previewOffer")}
            {downloadingPreviewOrder && <LoadingOutlined spin />}
          </a>
        </div>
      </div>
      {showLoadingPaid && (
        <div className="row mt-2">
          <div className="col col-md-12 col-xs-12 btn-pay-full-width text-center">
            <LoadingOutlined />
          </div>
        </div>
      )}
      {showPayment && auth?.user?.role == UserRole.BUYER && (
        <div className="row mt-2">
          <div className="col col-md-12 col-xs-12 btn-pay-full-width">
            <PaymentFormModal
              triggerShowModal={triggerShowModal}
              project={project}
            >
              <PaymentForm
                onCompletePayment={handleOnCompletePayment}
                project={project}
              ></PaymentForm>
            </PaymentFormModal>
          </div>
        </div>
      )}
      {showPayment && auth?.user?.role == UserRole.SELLER && (
        <div className="row mt-2">
          <div className="col col-md-12 col-xs-12 btn-pay-full-width">
            <ProjectListManualPaymentFormModal
              project={project}
              onComplete={handleOnCompletePayment}
            />
          </div>
        </div>
      )}
      {showPaid && <div className="paid-stamp"></div>}
    </div>
  );
}
export default ProjectPrices;
